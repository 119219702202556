.react-date-picker,
.form-section__datepicker {
  .form-control {
    background-color: white !important;
    padding: 2px;
    height: 31px;
    .react-date-picker__wrapper {
      height: 28px;
      border: 1px solid #c3ccda;
      border-radius: 0.25rem;
    }
  }
}

.react-date-picker__clear-button {
  display: none;
}

.react-date-picker__calendar {
  width: 250px;
  z-index: 999;
}

.react-date-picker {
  background-color: transparent;
  border: none !important;
}

.orderTbl {
  .tableWrapper.normalTbl {
    height: calc(100vh - 300px);
    table {
      input[type="checkbox"] {
        width: 18px;
        height: 13px;
      }
      input[type="number"] {
        width: 68px;
        height: 23px;
      }
    }
  }
}

.orderWrapper {
  overflow-y: auto;
}

.articleImg {
  width: 125px;
  height: 125px;
  margin: 5px 10px 5px 5px;
  border: 2px solid lightgray !important;
}

.smallImg {
  width: 40px;
  height: 40px;
}

.customCol2 {
  flex: 0 0 8.66667% !important;
}

.largeImgFull {
  display: flex;
  flex-direction: column;
  align-items: center;
  .input-position {
    width: 70%;
    position: relative;
    margin: auto;
    user-select: none;
    cursor: crosshair;
  }
}

.largeImg {
  width: 421px;
  height: 521px;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .largeImg {
    width: 321px;
    height: 421px;
  }
}

@media (max-width: 572px) {
  .detailWrap {
    .articleImgContainer {
      width: auto;
      .largeImg {
        width: 271px;
        height: 371px;
      }
    }
  }
}

@media (max-width: 420px) {
  .detailWrap {
    .articleImgContainer {
      width: auto;
      .largeImg {
        width: 241px;
        height: 341px;
      }
    }
  }
}

.selectedImg {
  border: 4px solid black !important;
}

.rightBtn {
  button {
    position: absolute;
    right: 128;
    top: 66;
    z-index: 2;
  }
}

.articleCard {
  font-size: 12px;
  height: 92%;
  width: calc(100% - 20px) !important;
  box-shadow: 0 3px 3px grey;

  &.selected {
    background-color: lightgray;
    border-left: 1px solid deepskyblue;
  }
}

.articleCard:hover {
  box-shadow: 0 5px 5px black;
  font-weight: bold !important;
}

.articleContainer {
  width: 100%;
  height: 100%;
  padding: 10px 0px 10px 23px;

  .listWrapper {
    margin-top: 50px;
    height: calc(100% - 30vh);
    width: calc(100% - 4vh);

    table th {
      text-transform: capitalize;
    }

    .card-footer {
      position: relative;
      height: 40px;
      display: inline-block;
    }

    .footerContent {
      position: absolute;
      top: 5;
      right: 0;
      display: flex;
      height: calc(100% - 10px);
      .btn {
        padding-top: 0.1rem;
      }
    }
  }

  .btnWrapper {
    display: flex;
    justify-content: flex-end;
    align-content: space-between;
    padding-right: 40px;
    span {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.portlet > .portlet-body.green,
.portlet.green {
  background-color: #26a69a;
}

.portlet.box {
  padding: 0px !important;
}

.portlet {
  border: 0 !important;
  padding: 10px;
}

.portlet {
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}

.portlet {
  box-shadow: 0 3px 3px grey, 0 3px 3px grey;
}

.tab-content > .active {
  display: block;
  visibility: visible;
}

.tab-content > .tab-pane {
  display: none;
  visibility: hidden;
}

.portlet.box.green > .portlet-title {
  background-color: #26a69a;
}

.portlet.box > .portlet-title {
  border-bottom: 0;
  padding: 0 10px;
  margin-bottom: 0;
  color: #fff;
}

.portlet > .portlet-title {
  border: 0 !important;
  margin-bottom: 0;
}

.portlet > .portlet-title {
  border-bottom: 1px solid #eee;
  padding: 0;
  margin-bottom: 10px;
  min-height: 41px;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -ms-border-radius: 2px 2px 0 0;
  -o-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}

.portlet.box > .portlet-body {
  background-color: #fff;
  padding: 10px;
  border: 0 !important;
  clear: both;
  border-radius: 0 0 2px 2px;
}

.portlet.box.green > .portlet-title > .caption {
  color: #ffffff;
  padding: 11px 0 9px 0;
  float: left;
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  padding: 10px 0;
}

.portlet.box > .portlet-title > .tools > a.collapse {
  //background-image: url("./assets/img/portlet-collapse-icon-white.png");
}

.portlet > .portlet-title > .tools > a:hover {
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  opacity: 0.8;
}

.portlet > .portlet-title > .tools > a.collapse {
  //background-image: url("./assets/img/portlet-collapse-icon-white.png");
  width: 14px;
  visibility: visible;
}

.portlet > .portlet-title > .tools > a {
  display: inline-block;
  height: 16px;
  margin-left: 5px;
  opacity: 1;
  filter: alpha(opacity=100);
}

a:hover {
  cursor: pointer;
  color: #1e1e1e;
}

a:hover,
a:active {
  outline: 0;
}

.summaryTbl {
  overflow: hidden;
  table {
    width: 100%;
    font-size: 12px;
    tbody {
      tr {
        td:first-child {
          width: 5%;
        }
        td:nth-child(2) {
          width: 15%;
          .form-control {
            height: 25px;
          }
        }
        td {
          width: 40%;
          padding-bottom: 0px;
        }
      }
    }
  }
}

.cardBtn-container {
  position: absolute;
  bottom: 0;
  right: 40;

  button {
    margin-left: 3px;
  }
}

.articleImgContainer {
  text-align: center;
  justify-content: center;
}

.sizeTableWrap {
  .stkText {
    font-size: 12px;
    font-weight: bold;
  }
  table {
    tr {
      td:first-child {
        width: 15%;
        .form-control {
          height: 17px;
        }
      }
      td:nth-child(2) {
        width: 20%;
      }
      td:nth-child(3) {
        width: 35%;
      }
    }
  }
}

.deleteIc {
  position: absolute;
  top: 30%;
  font-size: 20px;
  color: red;
}

.cardImgWrapper {
  height: 15rem;
  text-align: center;
}

.articleCardImg {
  width: initial;
  height: initial;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border: none !important;
}

.modal-title {
  width: 100%;
}

.modalHeader {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.form-checkbox span {
  width: 26px;
  height: 28px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 10px;
  background: #fff;
  border-radius: 4px;
  border: #2c9cd5 solid 1px;
  display: inline-block;
  z-index: 1;
}

.form-checkbox input[type="checkbox"],
input[type="radio"] {
  visibility: hidden;
}

.form-checkbox label {
  padding: 3px 0 3px 55px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 30px;
}

.checkboxWrap {
  margin-bottom: 30px;
}

.form-checkbox input[type="checkbox"]:checked + span:after,
input[type="radio"]:checked + span:after {
  opacity: 1;
  content: "";
  position: absolute;
  border: 4px solid #2c9cd5;
  border-top: none;
  border-right: none;
  background: transparent;
  transform: rotate(-45deg);
  width: 20px;
  height: 12px;
  top: 6px;
  left: 3px;
}

.form-checkbox {
  label {
    min-width: 160px;
    padding: 0px 40px;
  }
}

.addUserForm {
  .form-checkbox label {
    min-width: 400px;
    font-size: 14px;
  }
}

.left-Container {
  .multi-select {
    padding-left: 10px;
    padding-right: 12px;
    font-size: 13px;
    .dropdown-heading-value {
      font-size: 12px;
    }
  }
  .rmsc {
    --rmsc-main: #4285f4;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-bg: #ffff;
    --rmsc-p: 3px; /* Spacing */
    --rmsc-radius: 7px; /* Radius */
    --rmsc-h: 23px; /* Height */
  }
}

.right-Container,
.modal-body {
  .multi-select {
    font-size: 13px;
    .dropdown-heading-value {
      font-size: 12px;
      span {
        color: black;
      }
    }
  }
  .rmsc {
    --rmsc-p: 3px;
    --rmsc-radius: 4px;
    --rmsc-h: 26px;
    .dropdown-content {
      z-index: 10;
      width: 100%;
    }
  }
}

.container {
  color: #333333 !important;
  font-family: "Open Sans", sans-serif;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 13px;
  //direction: ltr;
  background-color: #364150 !important;
  height: 100vh !important;
  max-width: 100vw !important;
}

.loginWrapper {
  background-color: #eceef1;
  border-radius: 7px;
  width: 400px;
  margin: 40px auto 10px auto;
  padding: 40px;
  padding-top: 10px;
  overflow: hidden;
  height: 300px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
}

.loginHeading {
  color: #4db3a5;
  text-align: center;
  font-size: 28px;
  font-weight: 400 !important;
  margin-bottom: 25px;
}

.loginForm {
  padding: 0px;
  margin: 0px;
}

.login-btn {
  text-align: center;
}

.login-control {
  height: 43px !important;
  color: #8290a3 !important;
}
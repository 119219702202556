.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row {
  border: 1px solid #dee2e6;
  font-size: 12px;
}

.ReactVirtualized__Table__row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

// .ReactVirtualized__Table__headerColumn {
//   border: 1px solid #dee2e6;
// }

.ReactVirtualized__Table__headerTruncatedText,
.ReactVirtualized__Table__rowColumn {
  white-space: pre-wrap !important;
}

table .selected,
.ReactVirtualized__Table__row.selected {
  background-color: lightgray !important;
  border-left: 2px solid deepskyblue;
}

.ReactVirtualized__Table__Grid {
  background-color: white;
}
.ReactVirtualized__Table__headerColumn {
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dee2e6;
  display: flex;
  flex: 1;
  height: 100%;

  &:last-child {
    border-right: none;
  }
}
.ReactVirtualized__Table__headerRow {
  background-image: linear-gradient(#fff, #efefef);
  border: 1px solid #dee2e6;
  padding-right: 0 !important;
}
.ReactVirtualized__Table__row {
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}
.ReactVirtualized__Table__rowColumn {
  align-items: center;
  border-right: 1px solid #dee2e6;
  display: flex;
  flex: 1;
  height: 100%;
  min-width: 0;
  padding: 0.25rem;

  &:last-child {
    border-right: none;
  }
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 0px !important;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  padding: 0px;
  margin-left: 0px;
}

.tableWrapper.normalTbl {
  height: calc(100vh - 160px);
  overflow-y: auto;
  .table {
    position: relative;
    margin-bottom: 0px;
    border-collapse: collapse;
    .table-row {
      th,
      td {
        padding: 0.25rem;
      }
      th.table-cell {
        position: -webkit-sticky;
        position: sticky;
        top: -1;
        z-index: 2;
        box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd;
        background-color: white;
      }
    }
  }
}

.userTbl {
  .tableWrapper.normalTbl {
    height: calc(100vh - 180px);
  }
}

.activeUserTbl {
  .tableWrapper.normalTbl {
    height: calc(100vh - 345px);
  }
}

.timesheetTable {
  .tableWrapper.normalTbl {
    tr {
      th,
      td:nth-child(0) {
        width: 5%;
      }
      th,
      td:nth-child(5) {
        width: 2%;
      }
      .colFieldStaff {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        img {
          width: 80px;
          height: 80px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.page-header,
.page-sidebar-wrapper,
.page-content-wrapper,
.page-footer {
  transition: margin 0.3s;
}

.page-sidebar-wrapper {
  background-color: #364150;
  overflow: auto;
  height: 100%;
  width: 102%;

  ul li:last-child {
    padding-bottom: 5px;
  }
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
  background-color: #364150;
}

.navbar-collapse {
  display: block !important;
  height: 100%;
  padding-bottom: 0;
  overflow: visible !important;
  visibility: visible !important;
}

.page-sidebar .page-sidebar-menu>li.sidebar-toggler-wrapper,
.page-sidebar .page-sidebar-menu>li.sidebar-search-wrapper,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-toggler-wrapper,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-search-wrapper {
  border: 0 !important;
}

.page-sidebar .page-sidebar-menu>li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li {
  display: block;
  margin: 0;
  padding: 0;
  border: 0px;
}

.page-sidebar .page-sidebar-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-sidebar.navbar-collapse,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover.navbar-collapse {
  padding: 0;
  box-shadow: none;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active.open>a,
.page-sidebar .page-sidebar-menu>li.active.open>a {
  border-top-color: #3d4957;
  background-color: #1caf9a;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a,
.page-sidebar .page-sidebar-menu>li>a {
  border-top: 1px solid #3d4957;
  color: #b4bcc8;
}

.page-sidebar .page-sidebar-menu>li>a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a {
  display: block;
  position: relative;
  margin: 0;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  color: white;
}

.start:hover {
  background-color: burlywood;
}

.page-sidebar .page-sidebar-menu .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu {
  list-style: none;
  display: none;
  padding: 0;
  margin: 8px 0px 8px 0px;
}

.navbar-collapse.collapse {
  display: block !important;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
  visibility: visible !important;
}
body {
  overflow: hidden;
}

.rowMargin {
  margin-bottom: 20px;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  opacity: 0.4;
  background: black;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.cursor {
  cursor: pointer;
}

.link {
  color: deepskyblue;
  border: none !important;
  background-color: transparent;
  text-align: left;
}

.fr {
  float: right;
}

.mr-l-10 {
  margin-left: 10px !important;
}

.borderBlock {
  border: 1px solid black;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  background-color: #dde3ec;
  height: 43px;
  color: #8290a3;
  border: 1px solid #c3ccda;
}

.btn-primary,
.btn-group .btn.active {
  font-weight: 600;
  background-color: #1c7d74 !important;
}

.btn-primary:hover {
  background-color: #1c7d74;
}

.addIc {
  fill: white;
  font-size: 20;
}

.wrapper {
  padding: 20px;
  height: 100%;
}

.row {
  margin-bottom: 15px;
}

.textDanger {
  color: red;
}

.errInput {
  border-color: red;
}

.passwordIc {
  position: absolute;
  right: 10;
  top: 6px;
}

.loginForm {
  .passwordIc {
    top: 12px;
    font-size: 23px;
  }
}

.hide {
  display: none !important;
}

.menuHide {
  display: none !important;
}

.left-Container {
  padding: 0px;
}

.table-sm {
  font-size: 13px;
}

.filterToggleIc {
  position: absolute;
  right: 10px;
  top: -38px;
}

.tableWrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.displayWrapper {
  font-size: 12px;
}

.centerAlign {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-body {
  padding: 5px;
}

.btn-sm {
  font-size: 12px;
}

.txtFontSize {
  font-size: 14px;
}

.feedbackIc {
  position: absolute;
  flex-direction: column;
  z-index: 1040;
  align-items: center;
  display: flex;
  justify-content: cente;
}

.highlightText {
  font-weight: bold;
  font-size: 14px;
}

.footer {
  font-size: 14px;
  position: absolute;
  bottom: 100;
  border-top: 1px solid lightgrey;
  padding: 10px;
  font-weight: bold;
  width: 100%;
}

.sliderWrap {
  margin: 10px;
  color: #ffff;
  font-size: 12px;
  margin-bottom: 30px;
  .input-range {
    margin-top: 5px;
  }
  .input-range__label {
    color: #ffff;
  }
  .input-range__label--min {
    left: 10;
  }
  .input-range__label--max {
    right: 10;
  }
  .input-range__track--active {
    background: #1c7d74;
  }
  .input-range__slider {
    background: #1c7d74;
    border: 1px solid #1c7d74;
  }
}

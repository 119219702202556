.page-header.navbar {
  background-color: #2b3643;
  display: block;

  &.navbar-fixed-top {
    z-index: 1030;
  }

  width: 100%;
  padding: 0 20px 0 20px;
  margin: 0;
  border: 0px;
  padding: 0px;
  box-shadow: none;
  min-height: 46px;
  filter: none;
  background-image: none;

  .page-logo {
    float: left;
    display: flex;
    width: 50%;
    height: 46px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    h2 {
      color: white;
    }
  }

  .menu-toggler {
    cursor: pointer;
    opacity: 0.7;
    filter: alpha(opacity=70);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;

    &.sidebar-toggler {
      margin: 0px 10 0 0;
    }
  }

  .top-menu {
    margin: 0;
    padding: 0;
    float: right;
    width: 50%;
    .navbar-nav {
      display: flex;
      flex-direction: inherit;
      > li.dropdown {
        margin: 0px;
        padding: 0px 4px;
        .username {
          color: white;
        }
      }
    }
  }
}

@media (max-width: 592px) {
  .top-menu {
    .dropdown-user {
      width: 60%;
    }
    li {
      width: 20%;
    }
  }
}

.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.badge {
  color: white !important;
}
